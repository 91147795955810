<template>
	<div class="main">
		<div class="tit"><span>隐私政策</span></div>

		<div class="mainBox">
			<h6>引言</h6>
			<p>欢迎您使用由“ 时趣互动（北京）科技有限公司 ”（以下简称“公司”）合法运营并享有知识产权的“IP宇宙”。IP宇宙（以下简称为“我们”）尊重并保护所有用户的个人信息及隐私安全。为了给您提供更好的服务、更安全的网络环境，依据《中华人民共和国网络安全法》、《中华人民共和国个人信息保护法》等法律法规，我们制定了《隐私政策》（以下简称“本隐私政策”）向您说明我们在您使用IP宇宙的服务时我们将如何收集、使用、共享、转让、披露这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。<strong>其中，有关您个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。</strong></p>
			<p><strong>特别提示：如您是未满18周岁的未成年（特别是未满十四周岁的儿童），请您在监护人的陪同下及获得监护人同意的情况下，仔细阅读我们设置的未成年保护专门章节（以粗体提示）。</strong></p>
			<p>本隐私政策在您使用IP宇宙时与您息息相关，我们将按照本政策的约定处理和保护您的个人信息。因此，提示您仔细阅读和理解本隐私政策，并作出适当的选择。<strong>如果您不同意本政策的内容，可能导致无法完全享受我们提供的服务或者仅能使用部分功能，请您立即停止使用IP宇宙。如您继续访问或者使用IP宇宙，则表示您充分理解和同意本隐私政策的内容。</strong></p>
			<p>本隐私政策帮助您了解以下内容：</p>
			<p>一、我们如何收集和使用您的个人信息</p>
			<p>二、我们如何存储和保护您的个人信</p>
			<p>三、我们如何共享、转让、公开披露您的个人信息</p>
			<p>四、关于第三方提供产品或服务的特别说明</p>
			<p>五、我们对 Cookie 和同类技术的使用</p>
			<p>六、我们如何处理未成年人信息</p>
			<p>七、本隐私政策的修订和通知</p>
			<p>八、其他</p>
			<p></p>
			<h2>一、我们如何收集和使用您的个人信息</h2>
			<p>我们秉承正当、合法、必要的原则，收集和使用您在使用我们服务过程中主动提供或因自动化手段而收集产生的个人信息。我们收集到的您的个人信息仅用于本政策载明的以下用途，如用于未载明的其他用途或者目的，将以合理的方式向您告知，并在使用前再次征得您的同意：</p>
			<h6>1、账号注册、登陆及相关服务</h6>
			<p>当您注册、登陆IP宇宙及相关服务时，您可以通过手机号创建账号，我们将通过发送短信验证码的方式来验证您的身份是否有效，并且需要完善相关的网络身份识别信息（如密码）。您也可以选择电子邮箱账号进行注册登陆，我们将通过向您提供的邮箱账号发送邮件或者链接的方式来验证您的电子邮箱是否有效。<strong>如您拒绝提供前述注册登陆方式，您可能无法享受登陆状态下的部分相关服务，但是不影响您使用其他功能。</strong></p>
			<p><strong>在注册登陆时及注册登陆成功后，您随时可以根据自身需求选择填写或更改个人信息（如真实姓名、性别、公司名称、职位、个人电话号码、电子邮箱、昵称、头像、地区、住址、教育经历、个人介绍等）来完善您的信息，但如您不提供此类信息，不会影响您使用本产品和相关服务。</strong></p>
			<h6>2、向您提供相关产品或者服务</h6>
			<p><strong>为了保障您在使用IP宇宙时的安全性和稳定性，当您使用一键登录功能或者其他方式进行登陆后使用，我们会收集您的IP 地址、硬件型号、操作系统版本号、国际移动设备识别码（IMEI）、网络设备硬件地址（MAC）、软件版本号、网络接入方式及类型、操作日志等信息。</strong></p>
			<p>当您使用IP宇宙进行发布、上传图片、音视频、文本、过往案例等信息资料功能时，我们会请您授权照片、相机、麦克风权限。<strong>您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用IP宇宙的其他功能。</strong></p>
			<p>为了让您体验更全面、关联性更高的服务与功能，我们会收集您的浏览记录（您的关注、浏览、搜索等行为）、点击操作记录（您的兴趣、点赞、收藏、评论、发布记录及有关行为）和发布记录（您的上传、发布、推荐、转发、分享等行为及相应的文字、视频、图片等信息资料），在您使用IP宇宙时，会根据您的前述行为自动向您推送、展示相关作品或者IP资源。<strong>如您不希望看到类似推送或者希望减少频率，您可以选择“关闭推送”。具体可前往【我】-【设置】-【隐私】-【个性化选项】关闭个性化推荐机制。</strong></p>
			<h6>3、沟通、互动与反馈</h6>
			<p>当您上传、发布、转发、分享文本、图片、视频等信息资料时，可根据个人选择设置第三方的查看权限，<strong>如您未设置或者设置为全员可见，则您的操作成果即可被全部账号用户查看和浏览等。但是，如您设置为仅部分人员查看权限或者对于您自身的偏好、项目需求、城市信息等有特殊要求的，可根据实际情况采取限制查看、备注信息等行为。</strong>同时，您也可以通过留言、站内信等方式向我们咨询或者提出要求。</p>
			<p><strong>当您联系我们，向我们沟通、反馈IP宇宙使用功能意见或者建议、侵权事件、信息发布等情况时，我们需要您提供真实的用户身份、沟通或者反馈信息（涉事账号信息、您为了证明相关事实而提供的图片/视频/文字信息及有关信息）、联系方式、定位信息等以及您与我们的沟通记录，包括线上沟通记录、电话录音等，以便尽快解决问题和改进我们的产品或服务。同时，我们可能会以电话、短信、站内信及相关方式主动联系您，向您反馈结果或者处理方案等。</strong></p>
			<h6>4、第三方平台获取信息</h6>
			<p>当您申请的账号注册成功后，您可以通过第三方平台（如微信、QQ等）进行账号绑定，绑定后，<strong>如您选择授权使用第三方平台账号进行登录时，我们会从第三方平台账号的端口获取您共享的账号信息（如头像、昵称、地区、性别、职业等）与您的IP宇宙账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。</strong></p>
			<h6>5、授权同意的例外</h6>
			<p><strong>依据相关法律法规，出现下列情形，即使未获得您的授权或者同意，我们也可能会收集、使用您的个人信息：</strong></p>
			<p>（1）与我们履行法律法规义务相关的；</p>
			<p>（2）与国家安全、国防安全直接相关的；</p>
			<p>（3）与公共安全、公共卫生等公共利益直接相关的；</p>
			<p>（4）与犯罪侦查、起诉、审判和判决执行、行政处罚等直接相关的；</p>
			<p>（5）出于维护您或者其他人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
			<p>（6）所收集的您的个人信息是您自行向社会公众公开的；</p>
			<p>（7）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
			<p>（8）根据您的要求签订或履行合同所必需的；</p>
			<p>（9）为维护IP宇宙的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
			<p>（10）根据与您签署的在线协议或合同所必需的；</p>
			<p>（11）法律法规规定的其他情形。</p>
			<h2>二、我们如何存储和保护您的个人信</h2>
			<h6>1、存储</h6>
			<p>依照中华人民共和国的法律规定，我们对于从中国境内获得的个人信息存放于中国境内，不会将上述信息传输至境外。<strong>如果发生个人信息的跨境传输，我们会单独向您以站内通知或邮件的方式告知您个人信息出境的目的、接收方，并征得您的授权同意，同时，我们会严格遵守中国的法律法规，确保数据接收方有充足的数据保护能力来保护您的个人信息。</strong></p>
			<p>我们承诺在IP宇宙正常运营的情况下为您的个人信息提供合理的存储期限，一旦超出上述期限后或者我们终止服务/运营的，我们将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</p>
			<h6>2、保护</h6>
			<p>我们非常重视您的个人信息安全与保护，将努力采取各种符合行业标准的安全措施来保护您的个人信息，尽做大努力防止您的个人信息被毁坏、盗用、泄露、非授权访问、使用、修改、披露的风险。我们也将积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。</p>
			<p><strong>请您知悉和理解，您接入我们的服务所用的系统和通讯网络、电子邮件、第三方接入平台等，有可能因我们可控范围外的因素而出现问题，由于技术限制和可能存在的各种恶意手段，无法确保网络环境的绝对安全。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给其他人。</strong>此外，我们仅允许必须知晓这些信息的IP宇宙的运营方及其员工访问个人信息，并为此设置了严格的访问权限控制和监控机制。同时，我们还要求可能接触到您个人信息的所有人员签署保密协议并履行相应的保密义务。如果未能履行这些义务，将会被追究法律责任。</p>
			<p>为防止安全事故的发生，我们已按照法律法规的规定，制定了妥善的预警机制和应急预案。一旦发生，我们将立即启用紧急措施，并及时以邮件、信函、电话、短信通知的方式告知您。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
			<p>如果您对我们的个人信息保护有任何疑问或者意见建议，可通过本隐私政策最下方约定的联系方式联系我们。</p>
			<h2>三、我们如何共享、转让、公开披露您的个人信息</h2>
			<p>为了向您提供更完善、优质的服务，提高用户体验，我们可能会授权允许商业合作伙伴为您提供部分服务。但是，<strong>请您注意和理解，无论是哪种情况下的共享、公开披露，所导出的信息均是经过特殊化处理的。并且，我们会对信息数据的输出形式、流转、使用进行安全评估与处理，以保护数据安全。同时，我们会对合作伙伴进行严格的监督与管理，一旦发现其存在违规处理个人信息的行为，将立即停止合作并追究其法律责任。</strong></p>
			<p><strong>因您使用IP宇宙获得的个人信息，除非经您事先同意或者对于信息进行去标识化、匿名化处理后，我们不会向第三方共享、转让、公开披露您的个人信息，但是以下情况除外：</strong></p>
			<p>1、事先已得到您的同意或者授权：您在使用IP宇宙时或者基于本隐私政策对于个人信息做出同意或者授权。但是，如涉及到个人隐私信息、身份信息等能够单独或者结合起来锁定特定主体的个人信息，我们在会在共享、转让、公开披露前做出特殊处理，以防止第三方重新识别该信息的主体。</p>
			<p>2、法定情形下的共享、公开披露：根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供。</p>
			<p>3、为促进项目合作目的做出的共享与披露：为促进您与其他账号用户的交流与沟通，对于您使用IP宇宙期间所填写或者产生的信息，我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息。</p>
			<p>4、涉及合并、收购、资产转让或类似的交易的转让、披露：在IP宇宙运营期间，可能会发生合并、收购、资产转让等交易导致个人信息共享、转让的，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</p>
			<h2>四、关于第三方提供产品或服务的特别说明</h2>
			<p><strong>您在使用IP宇宙时可能涉及第三方产品或服务或链接至第三方提供的信息或服务，或者您通过第三方平台登陆使用IP宇宙，提醒您注意使用该第三方服务（包括您向该第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非本政策）约束，您需要仔细阅读其条款并自行决定是否接受。 </strong></p>
			<p><strong>请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。</strong>本隐私政策仅适用于我们收集、保存、使用、共享、披露信息的行为， 第三方收集、使用信息的行为应由其自身负责。</p>
			<h2>五、我们对Cookies和同类技术的使用</h2>
			<p>请您理解，Cookies和同类技术是互联网中的通用常用技术。当您使用IP宇宙时，需要您同意和接受Cookies或者同类技术，以便您能登录或使用依赖于Cookies的IP宇宙。<strong>您可以通过修改浏览器设置的方式或在移动设备中设置拒绝接受Cookies。但如果您选择拒绝接受Cookies，则您可能无法登录或使用IP宇宙。</strong></p>
			<h2>六、我们如何处理未成年人信息</h2>
			<p>我们非常重视对未成年人个人信息的保护，但是<strong>IP宇宙是一款面向全社会提供服务的产品，而非针对18周岁以下的未成年人进行的设计和开发。我们将依据国家相关法律法规的规定保护未成年人的个人信息，若您是18周岁以下的未成年人，在使用我们的产品或服务前，应事先取得您家长或法定监护人的书面同意。如涉及未成年人信息收集、使用，也只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果我们发现18岁以下的未成年人向我们提供了个人信息，我们将尽快采取措施删除这些信息。</strong></p>
			<h2>七、本隐私政策的修订和通知</h2>
			<p><strong>为提高服务质量和用户体验，IP宇宙及相关服务将不时更新与变化，我们也会适时对本隐私政策进行调整和更新，调整和更新后的隐私政策会以公示或者私信通知的方式告知您，但是，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。</strong></p>
			<h2>八、其他</h2>
			<p>如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请随时联系我们。联系方式可通过线上咨询、线下电话（联系电话：     ）等多种方式。在接到您的消息后，我们会及时审核您的身份及所咨询或者反馈的问的真实性并在审核后的十五个工作日内做出正式回复。</p>
		</div>

	</div>
</template>
<script type="text/javascript">
	export default {
		data() {
			return {

			}
		},
		created() {

		},
		components: {
		},
		methods: {


		}
	}
</script>
<style type="text/css" scoped="">
	.main {flex: 1;background: #FFFFFF;border-radius: 4px;padding: 20px 25px;margin-bottom: 30px;}
	.mainBox {color: #222;}
	.mainBox h2{font-size: 16px;padding: 20px 0;font-weight: bold;}
	.mainBox h6{font-size: 14px;padding: 20px 0;font-weight: bold;}
	.mainBox strong{font-weight: bold;}
	.mainBox p{font-size: 14px;line-height: 26px;}
</style>
